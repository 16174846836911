@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@250&display=swap');

.Sub-header-mobile {
  font-family: 'Spartan', sans-serif;
  font-size: 12pt;
  font-weight: 600;
  margin: 0;
}

.Profile-header-mobile {
  /*background-color: #282c34;*/
  font-family: 'Spartan', sans-serif;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(8px + 2vmin);
  color: #fffff0;
  position: relative;
  z-index: 1000;
}

.Display-name-mobile {
  width: 85%;
  border-bottom: 1.5px inset #fffff0;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  margin: 10px;
  margin-right: 20px;
  font-size: 17px;
  text-align: left;
}

.Display-picture-mobile {
  height: 22vmin;
  width: 22vmin;
  pointer-events: none;
  border-radius: 50%;
  object-fit: contain;
  background-color: black;
  margin: 10px;
}

.Banner-mobile {
  position: relative;
  top: -40px;
  max-height: 135px !important;
  overflow: hidden;  
}

.Banner-picture-mobile {
  width: 100%;
  object-fit: cover;
}

.Details-container-mobile {
  display: flex;
  flex-direction: column;
  position: relative;
  top: -30px;
  margin-bottom: -70px;
}

.Details-left-container-mobile {
  background-color: #fffff0; /*KhonaBiz Ivory*/
  width: 100%;
  position: relative; 
  padding-bottom: 30px;
  box-shadow: 10px -7px 8px rgba(20, 20, 20, 0.5) !important;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 56%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 96%, 0 100%);
  overflow: hidden;
}

.Details-left-inner-container-mobile {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: 'Spartan', sans-serif;
  font-size: 9pt;
  line-height: 1.5;
  letter-spacing: 1px;
  padding: 15px;
}

.Details-right-container-mobile {
  padding: 10px;
  margin-bottom: 30px;
  flex-grow: 1;
}

.Services-list-mobile {
  font-family: 'Spartan', sans-serif;
  font-size: 11pt;
  margin-top: 10px;
  line-height: 1.5;
}

.Services-list-mobile ul {
  text-align: left;
}

.Services-list-mobile li {
  list-style-type: disc;  
}

.Contacts-container-mobile {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  text-align: center;
  align-items: center;
}

.Other-links-container-mobile {
  display: inline-flex;
  flex-direction: column;
  padding-top: 25px;
  margin-bottom: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 12pt;
  font-weight: bold;
}

.Other-links-container-mobile a {
  margin-bottom: 10px;
}

.Platforms-container-mobile {
  display: inline-flex;
  flex-direction: row;
  width: 240px;  
  border-top: 1.5px solid lightgray;
  margin-bottom: 5px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.Platforms-container-mobile svg {
  font-size: 20pt;
  color: #036552;
}

.Contact-info-mobile {
  display: flex;
  flex-direction: column;  
  font-size: 12pt;
}

.Contact-info-mobile div {
  display: flex;
  flex-direction: row;
}

.Contact-info-mobile span {
  display: flex;
  flex-direction: row;
  margin-left: 10px;   
}

.Action-button-mobile {
  max-width: 80% !important;
  height: 45px;
  margin-bottom: 5px !important;
  align-self: center;
}

.Form-input-container-mobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;    
  margin-left: 10px;
}

.Input-mobile {
  width: 222px;
  margin-top: 10px !important;
}

.Button-mobile {
  height: 45px;
  margin: 10px !important; 
}

.Footer-container-mobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
}

.Footer-mobile {
  flex-grow: 1;
}

.Ratings-container-mobile {
  display: inline-block;
  width: 260px;
  padding: 5px;
  margin-bottom: 5px;
  border: 1px solid #fffff0;
  transform: skewX(-33deg);
  text-align: center;
}

.Ratings-mobile {
  transform: skewX(33deg);
}

.Logo-button-mobile {
  position: fixed;
  bottom: 0px;
  align-self: flex-end;
  /*z-index: 9000;*/
}