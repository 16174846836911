@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@250&display=swap');

.Sub-header {
  font-family: 'Spartan', sans-serif;
  font-size: 18pt;
  font-weight: 600;
}

.Profile-header {
  /*background-color: #282c34;*/
  font-family: 'Spartan', sans-serif;
  min-height: 25vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(8px + 2vmin);
  color: #fffff0;
  position: relative;
  z-index: 1000;
}

.Display-name{
  width: 70%;
  border-bottom: 1.5px inset #fffff0;
  padding: 15px;
  margin-bottom: 10px;
  margin-right: 20px;
  margin-left: 40px;
  font-size: 17px;
}

.Profile-service{
  width: 70%;
  border-bottom: 1.5px inset #fffff0;
  padding: 15px;
  margin-bottom: 20px;
  margin-right: 40px;
  margin-left: 20px;
  font-size: 17px;
}

.Display-picture {
  height: 22vmin;
  width: 22vmin;
  pointer-events: none;
  border-radius: 50%;
  object-fit: contain;
  background-color: black;
  margin: 5px;
}

.Banner {
  position: relative;
  top: -100px;
  margin-bottom: -90px;
  max-height: 450px !important;
  overflow: hidden;
}

.Banner-picture {
  width: 100%;
}

.Details-container {
  min-height: 400px;
  display: flex;
  flex-direction: row;
}

.Details-left-container {
  background-color: #fffff0; /*KhonaBiz Ivory*/
  width: 55%;
  min-height: 350px;
  position: relative;
  left: -70px;  
  box-shadow: 10px -7px 8px rgba(20, 20, 20, 0.5) !important;
  transform: skewX(-8deg);
}

.Details-left-inner-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  transform: skewX(8deg);
  font-family: 'Spartan', sans-serif;
  font-size: 11pt;
  line-height: 1.5;
  letter-spacing: 1.5px;
  padding-top: 25px;
  padding-left: 70px;
  padding-right: 55px;
}

.Details-right-container {
  padding: 20px;
  flex-grow: 1;
  margin-bottom: 50px;
}

.Services-list {
  font-family: 'Spartan', sans-serif;
  transform: skewX(8deg);
  margin-top: 20px;
  padding-left: 100px;
  padding-right: 70px;
  line-height: 1.5;
  letter-spacing: 1.5px;
}

.Services-list ul {
  text-align: left;
}

.Services-list li {
  display: inline-block;
  width: 50%;
  list-style-type: disc;  
}

.Contacts-container{
  display: flex;
  flex-direction: column;
  transform: skewX(8deg);
  margin-top: 40px;
  text-align: center;
  align-items: center;
}

.Other-links-container {
  display: inline-flex;
  flex-direction: column;
  padding-top: 25px;
  margin-bottom: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 15pt;
  font-weight: bold;
}

.Other-links-container a {
  margin-bottom: 10px;
}

.Platforms-container {
  display: inline-flex;
  flex-direction: row;
  width: 240px;  
  border-top: 1.5px solid lightgray;
  padding-top: 10px;
  margin-bottom: 5px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.Platforms-container svg {
  font-size: 22pt;
  color: #036552;
}

.Contact-info {
  display: flex;
  flex-direction: row;  
  font-size: 14pt;
}

.Contact-info div {
  display: flex;
  flex-direction: row;
  margin-right: 10px;  
  margin-left: 5px;  
}

.Call-to-action {
  display: inline-block;
  height: 55px;
  align-self: center;
  text-align: center;
  justify-content: center;
  margin-top: 10px;
}

.Action-button {
  min-width: 320px !important;
  height: 45px;
  margin-bottom: 20px !important;
  position: absolute !important;
  bottom: 20px;
  left: 40%;
}

.Form-input-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;    
  margin-left: 10px;
  margin-bottom: 10px;
}

.Input {
  width: 222px;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.Button {
  height: 45px;
  margin: 10px !important;
}


.Footer-container {
  width: 100%;
  position: fixed;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.Footer {
  flex-grow: 1;
  text-align: right;
}

.Ratings-container {
  display: inline-block;
  width: 350px;
  padding: 5px;
  margin-bottom: 5px;
  border: 1px solid #fffff0;
  transform: skewX(-33deg);
  text-align: center;
}

.Ratings {
  transform: skewX(33deg);
}

.Logo {
  height: 50px;
  align-self: flex-end;
}