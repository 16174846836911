@import url(https://fonts.googleapis.com/css2?family=Spartan:wght@250&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Spartan:wght@250&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  width: 100%;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  /* justify-content: center; */
  text-align: center;
}

.App-logo {
  width: 145px;
  pointer-events: none;
}

.App-initiate {
  /*background-color: #282c34;*/
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fffff0;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  /*background-color: #282c34;*/
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  font-size: 14px;
  color: white;
  border-bottom: 1px lightgray inset;
  padding: 5px;
}

.App-body {
  min-height: 85vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.cupertino-pane {
  padding: 10px;
}

.cupertino-pane-container .pane {
  background-color: #fffff0;
  text-align: center;
}

.pay-container input[type=submit]{
  color: lightgrey;
  border: solid 2px transparent !important;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #5fa1a1, lime);
  background-origin: border-box;
  box-shadow: 2px 1000px 1px grey inset;
}

.pay-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.pay-container p {
  padding-right: 20px;
  padding-left: 20px;
  color: #a1a1a1 !important;
  font-size: 14pt;
}

/* https://www.codegrepper.com/code-examples/whatever/input+mui+type+number+delete+arrows */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none;
}
.Sub-header-mobile {
  font-family: 'Spartan', sans-serif;
  font-size: 12pt;
  font-weight: 600;
  margin: 0;
}

.Profile-header-mobile {
  /*background-color: #282c34;*/
  font-family: 'Spartan', sans-serif;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  font-size: calc(8px + 2vmin);
  color: #fffff0;
  position: relative;
  z-index: 1000;
}

.Display-name-mobile {
  width: 85%;
  border-bottom: 1.5px inset #fffff0;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  margin: 10px;
  margin-right: 20px;
  font-size: 17px;
  text-align: left;
}

.Display-picture-mobile {
  height: 22vmin;
  width: 22vmin;
  pointer-events: none;
  border-radius: 50%;
  object-fit: contain;
  background-color: black;
  margin: 10px;
}

.Banner-mobile {
  position: relative;
  top: -40px;
  max-height: 135px !important;
  overflow: hidden;  
}

.Banner-picture-mobile {
  width: 100%;
  object-fit: cover;
}

.Details-container-mobile {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: relative;
  top: -30px;
  margin-bottom: -70px;
}

.Details-left-container-mobile {
  background-color: #fffff0; /*KhonaBiz Ivory*/
  width: 100%;
  position: relative; 
  padding-bottom: 30px;
  box-shadow: 10px -7px 8px rgba(20, 20, 20, 0.5) !important;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 56%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 96%, 0 100%);
  overflow: hidden;
}

.Details-left-inner-container-mobile {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
  font-family: 'Spartan', sans-serif;
  font-size: 9pt;
  line-height: 1.5;
  letter-spacing: 1px;
  padding: 15px;
}

.Details-right-container-mobile {
  padding: 10px;
  margin-bottom: 30px;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.Services-list-mobile {
  font-family: 'Spartan', sans-serif;
  font-size: 11pt;
  margin-top: 10px;
  line-height: 1.5;
}

.Services-list-mobile ul {
  text-align: left;
}

.Services-list-mobile li {
  list-style-type: disc;  
}

.Contacts-container-mobile {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 20px;
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
}

.Other-links-container-mobile {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-top: 25px;
  margin-bottom: 20px;
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 12pt;
  font-weight: bold;
}

.Other-links-container-mobile a {
  margin-bottom: 10px;
}

.Platforms-container-mobile {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 240px;  
  border-top: 1.5px solid lightgray;
  margin-bottom: 5px;
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.Platforms-container-mobile svg {
  font-size: 20pt;
  color: #036552;
}

.Contact-info-mobile {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;  
  font-size: 12pt;
}

.Contact-info-mobile div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.Contact-info-mobile span {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-left: 10px;   
}

.Action-button-mobile {
  max-width: 80% !important;
  height: 45px;
  margin-bottom: 5px !important;
  -webkit-align-self: center;
          align-self: center;
}

.Form-input-container-mobile {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;    
  margin-left: 10px;
}

.Input-mobile {
  width: 222px;
  margin-top: 10px !important;
}

.Button-mobile {
  height: 45px;
  margin: 10px !important; 
}

.Footer-container-mobile {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-bottom: 50px;
}

.Footer-mobile {
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.Ratings-container-mobile {
  display: inline-block;
  width: 260px;
  padding: 5px;
  margin-bottom: 5px;
  border: 1px solid #fffff0;
  -webkit-transform: skewX(-33deg);
          transform: skewX(-33deg);
  text-align: center;
}

.Ratings-mobile {
  -webkit-transform: skewX(33deg);
          transform: skewX(33deg);
}

.Logo-button-mobile {
  position: fixed;
  bottom: 0px;
  -webkit-align-self: flex-end;
          align-self: flex-end;
  /*z-index: 9000;*/
}
.Sub-header {
  font-family: 'Spartan', sans-serif;
  font-size: 18pt;
  font-weight: 600;
}

.Profile-header {
  /*background-color: #282c34;*/
  font-family: 'Spartan', sans-serif;
  min-height: 25vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  font-size: calc(8px + 2vmin);
  color: #fffff0;
  position: relative;
  z-index: 1000;
}

.Display-name{
  width: 70%;
  border-bottom: 1.5px inset #fffff0;
  padding: 15px;
  margin-bottom: 10px;
  margin-right: 20px;
  margin-left: 40px;
  font-size: 17px;
}

.Profile-service{
  width: 70%;
  border-bottom: 1.5px inset #fffff0;
  padding: 15px;
  margin-bottom: 20px;
  margin-right: 40px;
  margin-left: 20px;
  font-size: 17px;
}

.Display-picture {
  height: 22vmin;
  width: 22vmin;
  pointer-events: none;
  border-radius: 50%;
  object-fit: contain;
  background-color: black;
  margin: 5px;
}

.Banner {
  position: relative;
  top: -100px;
  margin-bottom: -90px;
  max-height: 450px !important;
  overflow: hidden;
}

.Banner-picture {
  width: 100%;
}

.Details-container {
  min-height: 400px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.Details-left-container {
  background-color: #fffff0; /*KhonaBiz Ivory*/
  width: 55%;
  min-height: 350px;
  position: relative;
  left: -70px;  
  box-shadow: 10px -7px 8px rgba(20, 20, 20, 0.5) !important;
  -webkit-transform: skewX(-8deg);
          transform: skewX(-8deg);
}

.Details-left-inner-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
  -webkit-transform: skewX(8deg);
          transform: skewX(8deg);
  font-family: 'Spartan', sans-serif;
  font-size: 11pt;
  line-height: 1.5;
  letter-spacing: 1.5px;
  padding-top: 25px;
  padding-left: 70px;
  padding-right: 55px;
}

.Details-right-container {
  padding: 20px;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  margin-bottom: 50px;
}

.Services-list {
  font-family: 'Spartan', sans-serif;
  -webkit-transform: skewX(8deg);
          transform: skewX(8deg);
  margin-top: 20px;
  padding-left: 100px;
  padding-right: 70px;
  line-height: 1.5;
  letter-spacing: 1.5px;
}

.Services-list ul {
  text-align: left;
}

.Services-list li {
  display: inline-block;
  width: 50%;
  list-style-type: disc;  
}

.Contacts-container{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-transform: skewX(8deg);
          transform: skewX(8deg);
  margin-top: 40px;
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
}

.Other-links-container {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-top: 25px;
  margin-bottom: 20px;
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 15pt;
  font-weight: bold;
}

.Other-links-container a {
  margin-bottom: 10px;
}

.Platforms-container {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 240px;  
  border-top: 1.5px solid lightgray;
  padding-top: 10px;
  margin-bottom: 5px;
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.Platforms-container svg {
  font-size: 22pt;
  color: #036552;
}

.Contact-info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;  
  font-size: 14pt;
}

.Contact-info div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-right: 10px;  
  margin-left: 5px;  
}

.Call-to-action {
  display: inline-block;
  height: 55px;
  -webkit-align-self: center;
          align-self: center;
  text-align: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 10px;
}

.Action-button {
  min-width: 320px !important;
  height: 45px;
  margin-bottom: 20px !important;
  position: absolute !important;
  bottom: 20px;
  left: 40%;
}

.Form-input-container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;    
  margin-left: 10px;
  margin-bottom: 10px;
}

.Input {
  width: 222px;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.Button {
  height: 45px;
  margin: 10px !important;
}


.Footer-container {
  width: 100%;
  position: fixed;
  bottom: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.Footer {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  text-align: right;
}

.Ratings-container {
  display: inline-block;
  width: 350px;
  padding: 5px;
  margin-bottom: 5px;
  border: 1px solid #fffff0;
  -webkit-transform: skewX(-33deg);
          transform: skewX(-33deg);
  text-align: center;
}

.Ratings {
  -webkit-transform: skewX(33deg);
          transform: skewX(33deg);
}

.Logo {
  height: 50px;
  -webkit-align-self: flex-end;
          align-self: flex-end;
}
