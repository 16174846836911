.App {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  text-align: center;
}

.App-logo {
  width: 145px;
  pointer-events: none;
}

.App-initiate {
  /*background-color: #282c34;*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fffff0;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  /*background-color: #282c34;*/
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: white;
  border-bottom: 1px lightgray inset;
  padding: 5px;
}

.App-body {
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cupertino-pane {
  padding: 10px;
}

.cupertino-pane-container .pane {
  background-color: #fffff0;
  text-align: center;
}

.pay-container input[type=submit]{
  color: lightgrey;
  border: solid 2px transparent !important;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #5fa1a1, lime);
  background-origin: border-box;
  box-shadow: 2px 1000px 1px grey inset;
}

.pay-container {
  display: flex;
  flex-direction: column;
}

.pay-container p {
  padding-right: 20px;
  padding-left: 20px;
  color: #a1a1a1 !important;
  font-size: 14pt;
}

/* https://www.codegrepper.com/code-examples/whatever/input+mui+type+number+delete+arrows */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none;
}